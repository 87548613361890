<template>
  <div class="inventari">
    <h1>INVENTARI</h1>
    <p>Ciao</p>
  </div>
</template>

<script>
  export default {
    name: "app",
    created:function() {
      localStorage.idmodule = '0'
    }
  }
</script>
